/* file drop zone */
#dropzone-outer {
    flex-grow: 1;
    font-size: 1rem;
    text-align: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
#drop-zone {
    width: 70%;
    max-width: 1200px;
    margin: 1rem auto 0 auto;
    padding: 2.5rem;
    align-self: center;
    background-color:#FFF;
    color: #fff;
    font-size: 1em;
    text-align: center;
    align-items: center;
    -webkit-box-shadow: inset 0px 0px 19px 0px rgba(0,0,0,0.4); 
    box-shadow: inset 0px 0px 19px 0px rgba(0,0,0,0.4);
    /*background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230c8d36' stroke-width='4' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");*/
    border-radius: 0.5em;
    outline-offset: -2px;
    outline: 2px white;
    outline-style: inset;
    
}

#drop-zone p {
    color: #333;

}

.h2_big {
    font-size: max(4rem, 5vw);
    
}

/*#drop-zone:hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230c8d36' stroke-width='4' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    box-shadow: inset 0px 0px 10px 0px rgb(0 0 0 / 30%);
    background-color: #f5f5f5;
}*/

/*#drop-zone-greensquare {
    background-color: #0C8D36;
    color: #ffffff;
    padding:0.5em;
    width: 62.5%;
	max-width: 250px;
    margin: 0 auto
    
}*/
#drop-zone-button {
    border: solid 1px #333;
    background-color: #c0c0c0;
    color: #333;
    padding: 0.75em;
    width: 62.5%;
    max-width: 250px;
    margin: 15px auto 15px auto;
    text-transform: uppercase;
}

#drop-zone-button:hover {
    background-color: #0c8d36;
    border: solid 1px #0c8d36;
    color: #FFF;
    cursor: pointer;
}


#dropzone-radios div {
    /*width: 95%;*/
    margin: 0 auto;
    text-align: left;
}

#dropzone-radios input[type='radio'] {
    margin: 10px;
}

#dropzone-radios table {
    width: 70%;
    font-size: 1em;
    margin: 0 auto;
}

#admin-set-discount-level {
    margin-inline: auto;
    font-size: 1em;  
    border-top: 2px dashed #FFF;
    border-bottom: 2px dashed #FFF;
    padding: 1em 2em;
    background-color: #1b2838;
}

#admin-set-discount-level input {
    margin-left: 2em;
}

.welcome-text{
    max-width: 70%;
    display: block;
    margin: 2em auto;

}

.adminPage {
    width: 100%;    
}

.flex{
    display:flex;
}

.grid{
    display:grid;
}

.adminPage div {
    /** width: 100% **/
}

.admin-editor-page {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    margin: 0 auto;
}

.admin-edit-column {
    margin: 1rem;
    font-size: 1.2rem;
}

.admin-edit-column input {
    max-width: 70px;
}

.admin-nav-button {
    color: #FFF;
    font-size: 1.2rem;
    margin: 5px;

}

.admin-cb-email {
    margin: 0.5rem;
    font-size: 1.2rem;
}


.admin-le-subject {
    width: 100%
}

.navbar_account{
    color: #666;

}

.admin-le-copyemail {
    width: 60% !important;
    margin-left: 2em;
}


.admin-nav-button-sel {
    color: #ff0000;
    font-size: 1.2rem;
    margin: 5px;
}

#admin-order-tools  {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 5%;

    justify-content: space-between;
}


.admin-delete-button {
    padding: 0.5rem;
    margin: 20px;
    background-color: #8d0c0c;
    color: #FFF;
    font-size: 1rem;
    border: solid 2px #fff;
}

.admin-delete-button:hover {
    
    background-color: #ff0000;
    }

.modalHeader-formula {
    margin-bottom: 10px;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.modalBody-formula {
    align-items: center;
    overflow: auto;
    inset: 50% auto auto 50%;
    width: 100%;
    height: 85%;
    padding-right: 20px;
    margin-top: 20px;
}

.formula-input {
    margin: 1rem;
    font-size: 1.0rem;
    width: 100%;
    height: 70%; 
    box-sizing: border-box;
}

.variable-label {
    font-size: 1em;
    line-height: 1.6em;
    font-weight: bold;
    
}

.previewButton {
    font-size: 1.3em;
    line-height: 1.6em;
    font-weight: bold;
}

.orderStatusContainer {
    width: 90%;
    margin:10px; 
    margin-left: 5%;
    background-color: #afafaf;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction:row;
    justify-content: space-between;
}

.orderStatusNames, .orderStatusPermissions{
    min-width: 150px;
    max-width: 15%;
    padding-right: 1em;
    padding-bottom: 1em;
    flex: 1;
    flex-wrap: wrap;
}

.orderStatusCustomer, .orderStatusAdmin{
    min-width: 250px;
    max-width: 30%;
    padding-right: 1em;
    flex: 2;
    flex-wrap: wrap;
}

.orderStatusButton{
    max-width: 10%;
}

.admin-edit-column h4{
    margin-top:20px;
}

.admin-edit-info{
    width: 100%;
    padding: 1em 1em 2em 1em;
    background-color: #f2f2f2; 
    color:#666;
    font-size: 0.9em;
    margin-bottom: 1.5rem;
    text-align: center;
}

.admin-edit-info h2{
    color:#666;    
}
.admin-edit-info span{
    padding:1em;   
}

/* grid-layout */

.grid-wrapper{
    display:grid;
    gap: 1.5rem;
    grid-template-columns: 35% auto;
    padding: 1.5em;
    margin-bottom: 150px    
}

.grid-wrapper h2{
    font-size: 1.5em;
    text-align: left;
}

.grid-element{
    width: 100%;
    
}

.grid-element textarea {
    width: 100% ;
    resize: vertical;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color:rgb(36, 36, 36);
    padding: 5px;
}

.grid-element input {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color:rgb(36, 36, 36);
    padding: 5px;
    margin-bottom:1em;
}

.grid-element button {
    margin-bottom:2em;
}

.admin-edit-control{
    width: 100%;
    z-index: 5;
    position: fixed;
    bottom: 0px;
    text-align: center;
    background-color: #bbbbbb;   
    padding: 0.5em;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2);
}

.adminPage h1{
    text-align: center;
    color: #FFF;    
}

@media (max-width: 50em) {
    .grid-wrapper{
        display:grid;
        gap: 1.5rem;
        grid-template-columns: 1fr;    
    }
}

.admin-sidebar{
    padding:1em;
    background: #bbb; 
    min-height: 100vh;
    width: 250px;  
    color:#666;  
}
.item-container {
    display: grid;
    grid-gap: 1em;
    padding-bottom: 1em;
    /* background-color: #FFF; */    
}


/* AKZENTFARBEN */

.item-name, .item-prices tr:nth-child(1) {
    /* color:#405f85; */
     color:#1b2838; 
}

.file-details-button {
    background-color: #405f85 ;
}



#item-element-wrapper:nth-child(even) {    
    background-color: #eee;   
}

#item-element-wrapper #item-element-wrapper  {    
    background-color: transparent;   
}


.item-task-container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    padding: 2%;
    max-width: 1500px;
    margin: 20px auto;
    box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 70%);
    background: #dcdcdc;
    
}

.item-container-notPrintable {
    background-color: #f3b0b0;
}

.wrapper_1500 {   
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;    
}

.center {
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-inline: auto;
}


.item-preview-details-wrapper{
    display: flex;
    width: 100%;
    margin: 0 auto;
    font-size: 0.9em;
    max-width: 1500px;
    justify-content: center;
}

.item-position-number {
    font-size: 1.5em;
    font-weight: 700 ;
    text-align: end;
    margin-right: 1em;
    width: 70px;
}

.item-name {
    font-size: 1.5em;
    font-weight: 900 ;
    margin-bottom: 0.5em; 
    line-height: 1; 
    text-transform: uppercase; 
    overflow-wrap: anywhere;
    word-wrap: break-word;
}

.item-details-row {
   display: grid;
   gap: 1em;   
   width:100%;
   grid-auto-columns: 202px 1fr 2fr 1fr;
   grid-template-areas: "preview params selections prices";

}

@media  (max-width: 87em) {
    .item-details-row {
        display: grid;
        gap: 1em;   
        width:100%;
        grid-auto-columns: 202px 1fr 1fr;
        grid-template-areas:    "preview selections prices "
                                "params selections prices";
     }
  }



  @media  (max-width: 71em) {
    .item-details-row {
        display: grid;
        gap: 1em;   
        width:100%;
        grid-auto-columns: 1fr 1fr ;
        grid-template-areas:    "preview  selections"
                                "params prices";
     }
  }

  @media  (max-width: 53em) {
    .item-details-row {
        display: grid;
        justify-items: center;
        gap: 1em;   
        width:100%;
        grid-auto-columns: 1fr 1fr ;
        grid-template-areas:    "preview params "
                                "selections selections "
                                "prices prices";
     }     
  }

  @media  (max-width: 39em) {
    .item-details-row {
        display: grid;
        gap: 1em;   
        width:100%;
        grid-auto-columns: 1fr;
        grid-template-areas:    "preview  "
                                " params "
                                "selections  "
                                "prices ";
     }
     .item-params-value{
        margin-inline: auto;
    }

    .item-params{
       text-align: center;
    }
  }


  .item-preview-window {
    grid-area: preview;
    width: max-content;
}

.item-params {
    grid-area: params;
    width: max-content;
}  

.item-selections {
    grid-area: selections;
    width: max-content;
}  

.item-prices {
    grid-area: prices;
    width: max-content;
}  

.item-details-row canvas {
    margin: 0 auto;
    border: 1px solid #333;
    max-width: 202px;
    height: 202px !important;
}

.item-preview {
    
   /* margin: 0 auto; */
}


.item-comment-textbox {
    justify-content: end;
    
}

#file-comment {
    box-sizing: border-box;
    margin-top: 3px;
    height: 65px;
    width: 90%;
    padding: 3px;
    font-size: 1em;
    font-family: 'Arial';
    resize:none;
}



.item-preview h4{
    margin: 0 0 0.5em 0;
}

.round-border {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background: #fff;
    border: 2px solid #333;
    /* color: #666; */
    text-align: center;
    margin-right: 10px;
    font-weight: bold;
    line-height: 1.8rem;
}

.remove-button-box{
    font-size: 1.5em;
    font-weight: 700;
    width: 70px;
    margin-left: 1em;
}

.delete-item-button:hover {
    color: red;
}


.preview-window {
    width: 200px;
    height: 200px;
    background-color: #df4343;
    color: #ffffff;
}

.vertical-divider {
    border-left: 1px solid #333;
    width: 1px;
    height: 200px;
    position: relative;
    margin: 0 0.5% 0 0.5%;
    display: none;
}

.item-params {
    width: 250px;
}

.item-params-row {
    display:flex;
    width: 100%;
}

.item-params-unit {
    width: 90px;
}

.item-selections-row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}

.item-selections-name {
    width: 125px;
    height: 35px;
}

.item-selections-dropdown .param-select {
   /* border: 1px solid #666;*/
   height: 35px;
    
}

.item-comment {
    /*color: #fff;*/
    font-size: 1em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.item-comment-row {
    display: flex;
    height: 25px;
    align-items: center;
}

.item-comment-label {
    margin-right: 1em ;
}

.item-comment-checkbox {    
    margin-right:1em;
}

#item-element-wrapper, .additional-info{
    padding-top: 3em;
    padding-bottom: 3em;
}

.additional-info, .item-container  {
    color:#333;
}

.file-details-button {
    /* border: solid 1px #333; */
    /* background-color: #CCC; */
    color: #FFF;
    padding: 0.5em 1em;
    margin-left: 2em;
    z-index: -1;
}

.file-details-button:hover {
    background-color: #0c8d36;
    /* border: solid 1px #0c8d36;  */
    color: #FFF;
    
}

.item-selections-tooltip {
    margin-left: 1em !important;
}

.item-actions {
    display: flex;
    justify-content: center;
    align-items: center;    
    min-width: 470px;
    margin: 0 auto;    
    color: #333;
    padding: 1em;
    flex-wrap: wrap;
    border-top: 1px dashed #cecece;
    width: 100%;
}

.item-actions .item-comment-tooltip{
    margin-right: 2em;
}

.item-actions-wrapper {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1332px;
    width: 90%;
    
}

.item-actions-options{
    display:flex;
    margin-inline: auto;
    margin-top: 1em;
}

.item-actions-actions{
    display:flex;
    margin-inline: auto;
    margin-top: 1em;
}

.item-actions-options label{
    margin-right:2em;
}





.item-prices {
    /* color: #fff; */
    font-size: 1em;    
}

.item-checkStatus {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    justify-content: center;
    padding: 1em;
    gap: 1em;
}




.item-prices p {
    margin: 0 0 5px 0;
    /*color: #fff;*/
}

.item-prices span {
    float: right;  
}

.pricelist {   
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-collapse: collapse;
}

.pricelist tbody{
    display: table;
    width: 100%;
    margin: 0px;
    padding: 0px;

}

.pricelist tr {
    height: 28px;
    display: flex;
    align-items: center;

}

.item-prices tr span, .item-prices span{
    padding-right: 5px;
    text-align: right;
}

.pricelist tr span{
    padding-right: 0px;
    text-align: right;
}

.item-prices tr:nth-child(2n) {
   /*  background-color: rgb(228, 228, 228);  */

}

.item-prices  td {
    width: max-content;
}


.item-prices tr:nth-child(1) {
  height: 30px; 
  font-size: 1.2em;
  font-weight: 900;
  align-items: flex-start;
}

.item-prices td:nth-child(1) {
    background-color:transparent;  
    min-width:70px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}

.item-prices td:nth-child(2) {
    background-color:transparent;  
    min-width:100px;
}

.item-prices td:nth-child(3n+3) {  
    min-width:100px;
}

.item-prices td:nth-child(3n+5) {  
    width:35px;
    display: flex;
    justify-content: flex-end;
}

.item-prices td {
    text-align: left;
}


.item-prices-incomplete {
    /* background-color: #df4343; */
    color: red;
    line-height: 2em;
}


.item-waiting {
    flex: 1 1 80%;
    align-items: center;
}

.item-waiting img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.param-select {
    width: 250px;
    font-size: 1em;
    height: 2.5em;
    padding-left: 5px;
}

.amount-input {
    width: 100px;
    text-align: center;
}

.price-input {
    width: 7em;
    font-size: 1em;
    padding:2px 5px;
    text-align: center;
    margin-right: 10px;
}

.amount-label {
    font-size: 1em;
    text-align: right !important;
}

.amount-radio {
    font-size: 1.1rem;
    margin-left: 10px;
}


.item-params, .item-selections, .item-comment, .item-prices, .item-position-number{
/*    background-color: rgb(245, 245, 245); */
    padding: 10px;
    
}

.order-summary {
    color: #333;

}

.labelGreen {
    color: green;
}

.labelRed {
    color: red;
}

.deliveryExpressWarning {
    color: red;
    font-size: 2em;
}
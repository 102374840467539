
/* header */
#header {
  height: 100px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 11%);
  position: fixed !important;
  top: 0px;
  bottom: auto;
  left: 0px;
  right: auto;
 
  padding-right: 20px;
 
  padding-left: 20px;
  z-index: 10;
}

#header .title {
    margin: 0;
    padding: 0 1rem;
    color: white;
}

.header-logo {
    padding-top: 0.85em;
    margin-right: 1em;
}


/* Navigtion Desktop */
@media only screen and (min-width: 1101px)
{

  .navbar{
      background: #fff;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
    }

    .navbar_account {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
    }
    
    #menuToggle input {
      display: none;
      
    } 
    .fa-firstdraft {
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    
    .nav-menu {
      display: grid;
      grid-template-columns: repeat(8, auto);
      grid-gap: 10px;
      list-style: none;
      text-align: center;
      width: fit-content;
      justify-content: flex-start;
      margin-right: 2rem;
    }
    
    .nav-item {
      display: flex;
      align-items: center;
      height: 80px;
    }
    
    .nav-links {
      color: #666;
      text-decoration: none;
      padding: 0.75rem;
    }
    
    .nav-links:hover {
      /* background-color: #c0c0c0; */
      /* border-radius: 4px; */
      transition: all 0.2s ease-out;
      border-bottom: 4px solid #0c8d36;
    }

    .active{
      /* background-color: #c0c0c0; */
      /* border-radius: 4px; */
      transition: all 0.2s ease-out;
      border-bottom: 4px solid #0c8d36;
    }

    .nav-button {
      color: #666;
      text-decoration: none;
      padding: 0.5rem 1.0rem;
      font-size: 1em;
      
    }
    
    .nav-button:hover {
      /* background-color: #c0c0c0; */
      /*  border-radius: 4px;  */
      transition: all 0.2s ease-out;
      border-bottom: 4px solid #0c8d36;
      
    }
    

    .fa-bars {
      color: #fff;
    }
    
    .nav-links-mobile {
      display: none;
    }
    
    .menu-icon {
      display: none;
    }
    
    .dropdown-menu {
      
      width: 200px;
      position: absolute;
      top: 60px;
      list-style: none;
      text-align: start;
      z-index:51;
    }
    
    .dropdown-menu li {
      background: #888888;
      cursor: pointer;
    }
    
    .dropdown-menu li:hover {
      background: #0c8d36;
    }
    
    .dropdown-menu.clicked {
      display: none;
    }
    
    .dropdown-link {
      display: block;
      
      text-decoration: none;
      color: #fff;
      padding: 16px;
    }
    
    .fa-caret-down {
      color: #000;
    }
}


/* Navigtion Mobile*/
@media only screen and (max-width: 1100px)
{ 

  .navbar {
    background: #fff;
    position:absolute;
    top: 0;
    left:0;
    font-size: 1em;
    width: 100vw;
    text-align: center;
    min-height: 100px;
    }

    .navbar_account {
      position: absolute;
      top:10px;
      right: 20px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
    }

    .navbar_account button{
      font-size: 1em;
      margin-left: 20px;
      max-width: 30vw;
    }

    .header-logo{
      margin-top:1em;
      width: 30vw;
      max-width:200px;
    }
  
              #menuToggle
              {
                display: block;
                position: absolute;
                top: 35px;
                left: 20px;
                height: 40px;
                width: 40px;
                z-index: 1;                
                -webkit-user-select: none;
                user-select: none;
              }
              
              #menuToggle a
              {
                text-decoration: none;
                color: #232323;                
                transition: color 0.3s ease;
              }
              
              #menuToggle a:hover
              {
                color: tomato;
              }
              
              
              #menuToggle input
              {
                display: block;
                width: 42px;
                height: 36px;
                position: absolute;
                top: -7px;
                left: -5px;                
                cursor: pointer;                
                opacity: 0; /* hide this */
                z-index: 2; /* and place it over the hamburger */                
                -webkit-touch-callout: none;
              }
              
              /*
              * Just a quick hamburger
              */
              #menuToggle span
              {
                display: block;
                width: 33px;
                height: 4px;
                margin-bottom: 5px;
                position: relative;                
                background: #666;
                border-radius: 3px;                
                z-index: 1;                
                transform-origin: 4px 0px;                
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            opacity 0.55s ease;
              }
              
              #menuToggle span:first-child
              {
                transform-origin: 0% 0%;
              }
              
              #menuToggle span:nth-last-child(2)
              {
                transform-origin: 0% 100%;
              }
              
              /* 
              * Transform all the slices of hamburger
              * into a crossmark.
              */
              #menuToggle input:checked ~ span
              {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: #232323;
              }
              
              /*
              * But let's hide the middle one.
              */
              #menuToggle input:checked ~ span:nth-last-child(3)
              {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
              }
              
              /*
              * Ohyeah and the last one should go the other direction
              */
              #menuToggle input:checked ~ span:nth-last-child(2)
              {
                transform: rotate(-45deg) translate(0, -1px);
              }
              
              /*
              * Make this absolute positioned
              * at the top left of the screen
              */
              #menu
              {
                position: absolute;
                  width: 100vw;
                  min-height: 100vh;
                  margin: 0 auto;
                  padding: 50px;
                  background: #ededed;
                  list-style-type: none;
                  -webkit-font-smoothing: antialiased;
                  transform-origin: 0% 0%;
                  transform: translate(-100%, 0);
                  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                  left: -25px;
                  top: -60px;
              }
              
              #menu li
              {
                padding: 10px 0;
                font-size: 1.1em;
              }

              .dropdown-menu {
                padding: 10px 0;
                font-size: 0.75em;
                width: 90%;
                z-index: 50;
                margin: 0 auto;
              }
              
              /*
              * And let's slide it in from the left
              */
              #menuToggle input:checked ~ ul
              {
                transform: none;
              }
            
              

}
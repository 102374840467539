/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  /* quicksand-regular - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/quicksand-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/quicksand-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/quicksand-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/quicksand-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/quicksand-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/quicksand-v30-latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
  }


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.4em;
}

a {
    color: #000;
    text-decoration: none;
}

ul {
    margin-left: 1.5rem;
    list-style: none;
}

button,
input[type='submit'] {
    border: none;
    background: none;
    color: unset;
    cursor: pointer;
}

h1,
h2 {
    font-family: 'Quicksand',Helvetica,Arial,Lucida,sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2em;
    padding: 1em;
    color: #333;
}


h3,
h4,
h5,
h6,
p {
    font-size: 1em;
    line-height: 1.6em;
    /*color: #666;*/
}

hr.solid {
    display: none;
    height: 1px;
    border: 0;
    border-top: 4px solid #0c8d36;
    margin: 15px auto 20px auto;
    padding: 0;
    width: 90px;
}

ul,
ul,
ol {
    margin: 1em 0;
}

/* general */



html {
    min-height: 100%;
    font-size: 14px;
  }

body {
    margin: 0px;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    padding: 0px;  
    background-color: #1b2838 !important;  
}


.flex {
    display: flex;
}

.grid {
    display: grid;
}


.container {
    margin-top: 100px !important;    
    margin: auto;    
    /* background-color: #405f85; */
    gap: 0px;
    padding: 0px;
}

#home-banner {
    height:400px;
    background-image: url("../img/Home_Banner.jpg");
    display:flex;
    align-items: center;
}

#banner-row {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    width: 100%;
}

#banner-text {
    background-color: rgba(0,0,0,0.45);
    width: 50%;
    margin-left: auto;
    margin-right: 0px;
    padding-top: 40px!important;
    padding-right: 20px!important;
    padding-bottom: 30px!important;
    padding-left: 1px!important;
}

#banner-text h1 {
   text-align: right;
}


.page-top {
    flex: 1 1 100%;
}


.item-column {
    flex: 1 1 68%;
}

.chat-column {
    display: flex;
    max-width: 1500px;
    flex-direction: column;
    width: 100%;
    margin: auto;
    color: white;
}

.paragraph {
    margin-top: 11px;
    margin-bottom: 0;
    line-height: 1.4em;
    /*color: #fff;*/
}

.paragraph_30 {
    margin-bottom: 0;
    line-height: 1.6em;
}

#file-list {
    line-height: 0;
    background-color: #FFF;
}

#additional-info-container {
    background-color: #ececec;
    box-shadow: 0px 6px 5px -5px rgba(0, 0, 0, 0.5);

}

.additional-info {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    max-width: 1332px;
    width: 90%;
    margin-inline: auto;
    flex-wrap: wrap;
}

.additional-info input[type='text'] {
    border: 1px solid #666;
    height: 2rem;
    font-size: 1em;
}

#field-projectID {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

#field-userOfferID {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

#field-userComment {
    display: flex;
    align-items: center;
    flex-grow: 20;
}

#field-orderStatus {
    width: 20%;
    margin-left: auto;
}


.input-projectID, 
.input-userOfferID {
    width: 100%;
    padding-left:1em;
    padding-right:1em;
    margin-left: 1em;
}

.input-projectID {    
    margin-right: 2em;    
}

#input-comment {
    width: 100%;
    margin: 0 0 0 8px;
    padding-left: 8px;
    font-size: 1em;
}


.chat-messages {
    overflow-y: scroll;
    height: 150px;
    border: solid 1px #eaeaea;
    padding: 8px;
    font-size: 1em;
    color: #fff;
}

.chat-input{
    width:100%;
    height: 30px;
    padding-left: 5px;
}

.chat-message-time{
    font-size: 10px;
}

.chat-message-user{
    font-weight: bold;
    color:#1fa83d
}


.order-summary {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    grid-gap: 1em;
    grid-gap: 1em;
    gap: 1em;
    justify-content: stretch;
    max-width: 1500px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 15px;
}

#order-summary-table {
    min-width: 230px;
    color: #fff;
}

.order-summary-column {
    margin: 2em auto;
    min-width: 250px;
}

.order-summary-column-ro {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 0.5% 1% 1% 1%;
    background: #fff;
    line-height: 1.5em;
}

#order-summary-table tr, 
#order-summary-table-ro tr  {
    height: 1.8em;
}

.order-summary-column td {
    text-align: right;
}

#order-summary-column {
    margin: 2em auto;
}

#order-summary-column span {
    text-align: right;
    min-width: 120px;
    display: block;   

}

.label-delivery-time {
    line-height: 1.5em;
    font-size: 1em;
}

.label-height-2 {
    line-height: 2em;
    font-size: 1.1em;
}

.label-height-2-white {
    line-height: 2em;
    color: #fff;
    font-size: 1.1em;
}

.label-height-2-red {
    line-height: 2em;
    color: rgb(255, 0, 0);
    font-size: 1.1em;
}

.label-payment-select {
    line-height: 2em;
    color: #fff;
    font-size: 1.1em;
    padding-top: 12px;
}

.label-payment-select-red {
    line-height: 2em;
    color: rgb(255, 0, 0);
    font-size: 1.1em;
    padding-top: 12px;
}

.overview {
    min-height: 65vh;
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
}

.overview h2, 
.container h2{
    color: #FFF;
    text-align: center;
}

.page-title h2, 
.info-area h2{
    /* color: #333; */
    font-family: Arial, Helvetica, sans-serifBold;
    font-style: bold;
    font-weight: 900;
    font-size: max(2.8rem, 3.55vw);
    color: #fff;
    text-shadow: 0 4px 4px rgba(0,0,0,.25);
    text-transform: uppercase;
    line-height: 1em;
}



.footer1 {
    text-align: center;
    background-color: #1b2838;
    padding: 2em;
    margin-inline: auto;
    width: 100%;
}    

.footer1 a{
    color: #FFF;
}   

#footer-container {
position: relative;
background-color: #1b2838;
border-top: 2px dashed white;
}


#payments-container {
    background-color: #696969;
}

#payments-inner-container {
    padding: 40px 0;
    max-width: 1500px;
    margin: auto;
    font-size: 1em;
}


#footer-inner-container {
    padding: 40px 0;
    max-width: 1500px;
    margin: auto;
    font-size: 1em;
}

#button-container {
    display: flex;
    max-width: 1500px;
    margin: 20px auto;
    justify-content: flex-end;
    flex-wrap: wrap;
    order: 2;
}

#button-container-even {
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 100%;
    justify-content: space-between;
}

#button-container-left {
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 100%;
}

#offer-footer {
    margin: 0 auto;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    max-width: 1500px;
    flex-wrap: wrap;
}

#offer-footer-left {
    margin: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    
}

#offer-footer-right {
    margin: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
}

#offer-footer-right p {
    text-align: right;
}

#offer-footer-right p {
    color: #fff;
}


#offer-footer-buttons {
    margin: 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#offer-address {
    margin-bottom: 5px;
}

#shipping-method {
    margin-bottom: 5px;
}


/* form */


.page-form {
    margin: 0 auto;
}

.page-form h2 {
    padding-bottom: 30px;
    font-size: 1.3em;
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap:1em;
}

.form-div100 {
    flex: 1 1 100%;
}

.form-div50 {
    flex: 1 1 45%;
}

.form-div80 {
    flex: 1 1 75%;
}

.form-div20 {
    flex: 1 1 15%;
}

.form-div70 {
    flex: 1 1 65%;
}

.form-div30 {
    flex: 1 1 25%;
}



.form label {
    font-size: 1em;
}

.form input[type='text'],
.form input[type='password'],
.form input[type='email'] {
    width:100%;
    padding: 0.3rem;
    font-size: 1em;
    border: 1px solid #c2c2c2;
    border-radius: 0px;
    background-color: #fff;
}

.form select {
    width: 100%;
    padding: 0.3rem;
    font-size: 1em;
    border: 1px solid #c2c2c2;
    border-radius: 0px;
    background-color: #ffff;
}

.form input[type='radio'] {
    display: inline;
    margin: 0.3rem;
    padding: 0.3rem;
}

.form .radioLabel {
    display: inline;
    margin: 0.3rem;
    padding: 0.3rem;
}

.form input:focus {
    outline: none;
    border: 1px solid #268ee4;
    box-shadow: inset 0px 0px 6px rgba(38, 141, 228, 0.5);
}

.form input[type='submit'] {
    padding: 0.3rem 0.5rem;
    border: 1px solid #6aa051;
    background-color: #bce4aa;
    font-size: 1.1rem;
    border-radius: 0px;
}

/* error notice */

.error-notice {
    border: 1px solid #e07c7c;
    background-color: #f8d6d6;
    padding: 0.5rem;
    border-radius: 8px;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    Color: #FF0000;
}

.error-notice button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #df4343;
    color: #ffffff;
}

.user-hint {
    max-width: 1500px;
    border: 2px solid red;
    background-color: #f2c2c2;
    padding: 0.5rem;
    display: flex;
    margin: 2em auto;
    color: #333;
    width: 90%;
}



/* info */

#upload-box{
    max-width: 1500px;
    width: 90%;
    min-width: 470px;
    margin-inline: auto;
    color: #fff;
    flex-wrap: wrap;
    gap: 20px; 
    align-content: center;    
    color: #FFF;
    min-height: 40vh;
    padding: 5vh 5vh 10vh 5vh;
    align-items: center;
}

#upload-box .page-title{
    margin: 0 auto;
}

#info-box  {
    max-width: 1500px;
    width: 90%;
    min-width: 470px;
    margin-inline: auto;
    color: #fff;
    flex-wrap: wrap;
    gap: 20px; 
    align-content: center;    
    color: #FFF;
    padding: 2em;
}

#info-box-background {
    /* background: rgb(96,146,208);*/ 
    background: radial-gradient(circle, rgba(96,146,208,1) 0%, rgba(64,95,133,1) 28%, rgba(27,40,56,1) 100%); 
    /* background-image: url("https://www.authentic-blades.com/files/AB_Neu/Bilder/radial_lines.svg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;  */
    
}

#info-box .page-title{
    flex-grow: 1; 
    margin: auto;   
}

#info-box .page-title h2{
    padding: 0; 
}

#minordervalue-box {
    padding: 2em;
    text-align: center;
    background-color: #1b2838;
    width: 100%;
    min-width: 470px;
    color: #FFF;
}

.info-area {
    margin: 0 auto;
    font-size: 1rem;
    width: 95%;
    max-width: 1200px;
}

.info-area h2 {
    text-align: center;
    font-size: 36px;
    
}


.page-title-left h2 {
    margin-top: 0;
    text-align: left;
}


.copy-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #0b7544;
    color: #ffffff;
}

.info-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* color: #0c8d36; */
    background-color: #999;
    color: #fff;
    text-align: center;
    display: flex; /* or inline-flex */
  align-items: center; 
  justify-content: center;
  font-size: 1em;
  padding-bottom:1px;
}

.info-button:hover {
    background-color: #0c8d36;
}

.info-button strong{
    margin: 0;
    padding: 0;
}

.delete-button {
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 10px;
    /*border: solid 2px rgb(255, 255, 255);*/
    background-color:rgb(153, 8, 8);
}

.delete-button:hover {
    background-color: rgb(255, 0, 0);
}


/* TEST neuer Delete-Button */


.delete-button-fresh {
    display:block;
    width:30px;
    height:30px;
    background: rgb(184, 51, 51);
    border-radius:15px;
    border:2px solid rgb(184, 51, 51);
    transition: all .5s;
    overflow:hidden;
  }
  
  .delete-button-fresh:hover {
    display:block;
    width:150px;
    height:30px;
    background: rgb(184, 51, 51);
    border-radius:20%px;
    border:2px solid rgb(184, 51, 51);
    cursor:pointer;
    transition: all .5s;
    overflow:hidden;
  }
  
  .delete-button-fresh-content {
    font-family:Helvetica;
    font-size:12px;
    font-weight:normal;
    line-height:30px;
    color: rgba(255, 255, 255, 1);
    width:150px;
    position: relative;
    transition: all 1s;
    left:150px;
    height:30px;
  }
  
  .delete-button-fresh-content:before {
    content: '🗙';
    position: absolute;
    right: 272px;
    top: -2px;
    background-color: rgba(255, 255, 255, 1);
    width:30px;
    height:30px;
    border-radius:15px;
    color: rgb(184, 51, 51);
  }
  
  .delete-button-fresh:hover .delete-button-fresh-content {
    font-family:Helvetica;
    font-size:12px;
    font-weight:normal;
    line-height:30px;
    color: rgba(255, 255, 255, 1);
    width:150px;
    transition: all 1s;
    left:0px;
    background-color: rgb(184, 51, 51);
    height:30px;
    border-radius:8px;
  }

  /* ENDE TEST neuer Delete-Button */

.edit-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #4381df;
    color: #ffffff;
}

.main-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #24a36e;
    color: #ffffff;
}



.view-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #a649bd;
    color: #ffffff;
}

.download-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #d87a2c;
    color: #ffffff;
}

.pdf-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #bdb549;
    color: #ffffff;
}

.update-button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #34852c;
    color: #ffffff;
}

.delete-amount-button {

    width: 20px;
    height: 20px;
    /*color: red;*/
    background-color:#fff;
    border: solid 1px #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    /* padding-bottom: 1px;*/
    
}

.delete-amount-button:hover {
    background-color:red;
    color: #fff;
    border: solid 2px red;
}


.add-amount-button {
    width: 20px;
    height: 20px;
    /*color: red;*/
    background-color:#fff;
    border: solid 1px #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    
    /* padding-bottom: 1px;*/
}

.add-amount-button:hover {
    background-color: #0c8d36;
    color: #fff;
    border: solid 2px #0c8d36;
}

.page-button {
    padding: 0.5rem;
    margin-left: 20px;
    color: #333;
    font-size: 1rem;
    border: solid 2px #333;
}

.page-button:hover {
background-color: #fff;
color: #333;
border: solid 2px #fff;
}

.page-button-left {
    padding: 0.5rem;
    color: #333;
    font-size: 1rem;
    border: solid 2px #333;
}

.page-button-left:hover {
    background-color: #333;
    color: #fff;
    border: solid 2px #333;
}

.page-button-white {
    padding: 0.5rem;
    margin: 20px;
    color: #FFF;
    font-size: 1rem;
    border: solid 2px #fff;
}

.page-button-white:hover {
    background-color: #fff;
    color: #333;
    border: solid 2px #fff;
}

.page-button-green {
    padding: 0.5rem;
    color: #333;
    border: solid 2px #333;
    font-size: 1rem;
}

.page-button-green:hover {
    background-color: #0c8d36;
    color: #fff;
    border: solid 2px #0c8d36;
}

.page-button-action {
    padding: 0.5rem;
    margin: 20px;
    background-color: #0c8d36;
    color: #FFF;
    font-size: 1rem;
    border: solid 2px #fff;
}

.page-button-action:hover {
    padding: 0.5rem;
    margin-left: 20px;
    background-color: #FFF;
    color: #0c8d36;
    font-size: 1rem;
    border: solid 2px #0c8d36;
}

.alert-button-grey {
    padding: 0.5rem;
    color: #333!important;
    border: solid 2px #333!important;
    background: none!important;
    border-radius: 0!important;
    font-size: 1rem!important;
    width: 80px;
}

.alert-button-grey:hover {
    background-color: #333!important;
    color: #fff!important;
    border: solid 2px #333!important;
}

.page-button-grey {
    padding: 0.5rem;
    color: #333;
    border: solid 2px #333;
    font-size: 1rem;
    margin-inline: 1em;
}

.page-button-grey:hover {
    background-color: #333;
    color: #fff;
    border: solid 2px #333;
}

.page-button-disabled {
    padding: 0.5rem;
    margin: 20px;
    color: grey;
    font-size: 1rem;
    border: solid 2px grey;
}

.page-button-disabled:hover {
    color: grey;
    border-color: grey;
    background-color: transparent;
}

.chat-button-white {
    padding: 0.5rem;
    color: #fff;
    font-size: 1rem;
    border: solid 2px #fff;
    margin-right: auto;
}

.chat-button-white:hover {
    background-color: #fff;
    color: #333;
    border: solid 2px #fff;
}

#chat-order-footer {
    display: flex;
    max-width: 1500px;
    margin: auto;
    margin-top: 3%;
}

#chat-offer-footer {
    display: flex;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
}

#chat-column-1 {
    width: 50%;
}

#chat-column-2 {
    width: 50%;
}

#chat-input {
    width: 90%;
    margin-bottom: 20px;
}




.position-remove-button {
    padding: 0.5rem;
    margin-left: 20px;
    /* color: #ffffff; */
    font-size: 1rem;
    border: solid 2px #333;
}

.position-remove-button:hover {
    background-color: red;
    color: #fff;
    border: solid 2px red;
}


.AccountLink {
    margin: 0;
    padding: 0 1.7rem;
    color: #666;
    font-size: 1em;
}

.link-white {
    color: white;
    text-decoration: underline;
}

/* adress list */

#adress-list .adress-item {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    height: 120px;
    padding: 1.5%;
    font-size: 1em;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: solid 1px #c2c2c2;
}

.container #overview table{
    width: 100%;
}

.container #overview tr:nth-child(even) {
    background-color: #ffffff;
}

.container #overview tr:nth-child(odd) {
    background-color: #ffe0e0;
}

.document-list, .document-list h2 {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
    padding: 0.3rem 0.5rem;
    font-size: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: #666;
}

.document-list table{
    margin: 0 auto;
}


#orderAdresses {
    display: flex;
}

#orderAdresses div{
    width: 400px;
    font-size: 1.1em;
    margin:2rem;
}

.address-label {
    margin-bottom: 10px;
}

.address-select-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
}

#address-select-box {
    display: flex;
    flex-direction: column;
}

#address-select-row {
    display: flex;
    flex-direction: column;
}

#address-select-label {
    display: flex;
    align-items: center;
}

#address-button-column {
    display:flex;
    width: 140px;
    padding-right: 1%;
    justify-content: flex-end;
}

#address-button {
    margin-left: 8px
}

.address-item-column-1{
    width: 25%;
}
.address-item-column-2{
    width: 25%;
}
.address-item-column-3{
    width: 25%;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.6)!important;
}



#rebate {
    width: 3rem;
    margin: 1rem;
}

#add-address-button {
    font-size: 1em;
    text-decoration: underline;
    text-align: left;
    color: #fff;
}

button, i

.adressLabel {
    font-size: 1em;
}

.fileDetailLabel {
    line-height: 35px;
    font-size: 1em;
    /*color: #fff;*/
    margin: auto;
}

.ag-theme-alpine {
    height: 65vh;
    margin: 40px auto;
    max-width: 1500px;

}

.ag-theme-alpine .ag-ltr .ag-floating-filter-button {
    margin-left: 18px;
    display: none;
}


.row-item-name {
    display: flex;
    padding: 0;
    width: 100%;
    /*max-width: 1500px;*/
    align-items: center;
    margin: 0 auto;
}

.row-item-name h4 {
    color: #333;
    font-size: large;
}


.delivery-item {
    display: flex;
    flex-wrap: wrap;
    gap: 1m;
    font-size: 1em;
    justify-content: space-between;
    background-color: #e6e6e6;
    margin: 1em;
}


.label-not-printable {
    font-size: 2em;
    background-color: #e6e6e6;
    color:#ff0000;
}

/*benoetigt um links in react-tooltip clickbar zu machen*/
.__react_component_tooltip { pointer-events: auto !important; }

.Select-value-label, .Select-option {
    height: 100px;
    white-space: pre-wrap;
  }



  
/* media queries */

@media screen and (max-width: 550px) {
    #header .title {
        font-size: 1.2rem;
    }

    #header .auth-options button {
        font-size: 0.9rem;
        padding: 0 0.5rem;

    }
}

.react-confirm-alert-body {
    border-radius: 0;
    padding: 25px 40px;
    background: linear-gradient(
180deg
, rgba(2,0,36,1) 0%, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 40%)
}
;


.react-confirm-alert-body h1 {
    font-size: 1.3em;
    margin-bottom: 10px;
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.4);
}

#create-order-button-footer {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1500px;
    margin: 2em auto;
}

#legal-select-box {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    order: 1;
    
}

.legal-item {
    margin-bottom: 10px;    
    color: #fff;
}

#login-register-box {
    max-width: 600px;
    background: #fff;
    margin: 100px auto 100px auto;
    padding: 3%;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 40%);
    background: linear-gradient(
        180deg
        , rgba(2,0,36,1) 0%, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)!important;
            box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 40%);
}

#register-requestpw {
    font-size: 1em;
    line-height: 1.8em;
}
#requestSuccess {
    padding-top: 10px;
    font-weight: bold;
}

#wrap-div {
    line-height: 0;
    min-height: 70vh;
}

.overlay-page-form {
    margin: 0 auto;
    display: flex;
    align-items: center;
    align-content: center;
}

#thanks-box  {
    width: 90%;
    color:#333 !important; 
    max-width: 650px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2%;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 40%);
}

#thanks-box h2{
    color:#333 !important; 
}

.ReactModal__Content {
    overflow:unset!important;
    border-radius: 0!important;
    padding: 1.7% 2%!important;
    color: #333;
}

.modalHeader {
    margin-bottom: 10px;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.modalHeader h2{
    font-family: 'Quicksand',Helvetica,Arial,Lucida,sans-serif;
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    padding:0 !important;
    color: #333;
    margin: 0 !important;
}


.modalBody {
    align-items: center;
    overflow: auto;
    inset: 50% auto auto 50%;
    width: 100%;
    height: 85%;
    padding-right: 20px;
    margin-top: 20px;
    color: #333;
}

.modalHeader hr, .modalBody hr {
    margin-top: 2em;
    margin-bottom: 2em;  
    border: 0;
    border-top: 1px dashed grey;
    
}

.modalBody h2{
    font-family: 'Quicksand',Helvetica,Arial,Lucida,sans-serif;
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    padding:0 !important;
    color: #333;
    margin: 0;
}

.form-div100 h2 {
    margin-top: 1em;
}

.modalBody label{
    
}

#buttonX {
    position: relative;
    float: right;
	color: #444;
    font-size: 30px;
}

.minValueLabel {
    color:#fff;
    display:block;
    text-align: center;
    width: 90%;
    max-width: 1500px;
    margin: 2em auto;
}

.wrapper_1500{
    max-width: 1500px;
}

.gridCheckBoxCell{
    
}

.gridButton {
    margin:0.5em;
}

.gridCheckbox
{
    margin:1em;
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}